import { Component } from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import { sporsmalShape } from "utils/PropTypes";
import { connect } from "react-redux";
import { getSporsmalSvar } from "ducks/skjemaSvarDuck";
import { filterByType, getValidation } from "ducks/validationDuck.js";
import { svarTypes } from "constants/svarTypes";
import { scrollToId, ScrollType } from "utils/scrollUtils";
import { isSporsmalBesvart } from "logic/validation";
import { FormattedMessage } from "react-intl";
import { getTextWithLocale } from "utils/localeUtils";

import { RichTextView } from "@utdanningsdirektoratet/richtext";
import PositivNegativSvar from "./components/PositivNegativ";
import JaNeiSvar from "./components/JaNeiSvar";
import EnigUenigSvar from "./components/EnigUenigSvar";
import EttValg from "./components/EttValg";
import FlereValg from "./components/FlereValg";
import HeltallSvar from "./components/HeltallSvar";
import KortTekstSvar from "./components/KortTekstSvar";
import DatoSvar from "./components/DatoSvar";
import FylkeKommuneSvar from "./components/FylkeKommuneSvar";
import Kommentar from "./components/Kommentar";
import Vedlegg from "./components/vedlegg";

class SporsmalSvar extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.sporsmalSvar !== this.props.sporsmalSvar ||
      nextProps.validation.length !== this.props.validation.length ||
      !nextProps.validation.every((e, i) => e === this.props.validation[i]) ||
      nextProps.locale !== this.props.locale ||
      nextProps.ingenMerknader !== this.props.ingenMerknader
    );
  }

  render() {
    const { sporsmal, sporsmalSvar, updateSporsmalSvar, validation, forhandsvisning, locale, readonly } = this.props;
    const { tittel, positivNegativ, jaNei, enigUenig, ettValg, flereValg, heltall, kortTekst, dato, fylkeKommune, kommentar, vedlegg, id } =
      sporsmal;

    const tittelRiktigMalform = getTextWithLocale(tittel, locale);

    const tittelFormatted = tittelRiktigMalform ? tittelRiktigMalform.replace(/\n/g, "") : "";
    const noAnswers = this.props.readonly && !forhandsvisning && !isSporsmalBesvart(sporsmalSvar);

    return (
      <div className="Sporsmal" id={`sporsmal_${id}`}>
        <Element name={scrollToId(ScrollType.SPORSMAL, id)} />
        {tittelFormatted ? (
          <div className="Sporsmal-heading">
            <div className="Sporsmal-heading-title">{tittelFormatted}</div>
            {sporsmal.brodTekst ? <RichTextView html={getTextWithLocale(sporsmal.brodTekst, locale)} /> : null}
          </div>
        ) : null}

        {noAnswers ? (
          <div>
            <div className="Sporsmal-body Sporsmal-body--italic">
              <FormattedMessage id="validation.ikkeSvart" />
            </div>
          </div>
        ) : (
          <div>
            {positivNegativ ? (
              <PositivNegativSvar
                id={id}
                updateSvar={updateSporsmalSvar}
                positivNegativSvar={sporsmalSvar.positivNegativSvar}
                obligatorisk={positivNegativ.obligatorisk}
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                validationErrors={filterByType(svarTypes.POSITIV_NEGATIV, validation)}
              />
            ) : null}
            {jaNei ? (
              <JaNeiSvar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                jaNeiSvar={sporsmalSvar.jaNeiSvar}
                obligatorisk={jaNei.obligatorisk}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.JA_NEI, validation)}
              />
            ) : null}
            {enigUenig ? (
              <EnigUenigSvar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                enigUenigSvar={sporsmalSvar.enigUenigSvar}
                obligatorisk={enigUenig.obligatorisk}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.ENIG_UENIG, validation)}
              />
            ) : null}
            {ettValg ? (
              <EttValg
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                ettValg={ettValg}
                obligatorisk={ettValg.obligatorisk}
                sporsmalSvar={sporsmalSvar}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.ETT_VALG, validation)}
              />
            ) : null}
            {flereValg ? (
              <FlereValg
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                flereValg={flereValg}
                obligatorisk={flereValg.obligatorisk}
                sporsmalSvar={sporsmalSvar}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.FLERE_VALG, validation)}
              />
            ) : null}
            {heltall ? (
              <HeltallSvar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                heltallSvar={sporsmalSvar.heltallSvar}
                obligatorisk={heltall.obligatorisk}
                sporsmalSvar={sporsmalSvar}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.HELTALL, validation)}
              />
            ) : null}
            {kortTekst ? (
              <KortTekstSvar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                kortTekstSvar={sporsmalSvar.kortTekstSvar}
                obligatorisk={kortTekst.obligatorisk}
                type={kortTekst.type}
                sporsmalSvar={sporsmalSvar}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.KORTTEKST, validation)}
              />
            ) : null}
            {dato ? (
              <DatoSvar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                datoSvar={sporsmalSvar.datoSvar}
                obligatorisk={dato.obligatorisk}
                sporsmalSvar={sporsmalSvar}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.DATO, validation)}
              />
            ) : null}
            {fylkeKommune ? (
              <FylkeKommuneSvar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                fylkeKommuneSvar={sporsmalSvar.fylkeKommuneSvar}
                fylkeKommune={fylkeKommune}
                sporsmalSvar={sporsmalSvar}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.FYLKEKOMMUNE, validation)}
              />
            ) : null}
            {kommentar ? (
              <Kommentar
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                kommentar={sporsmalSvar.kommentar}
                obligatorisk={kommentar.obligatorisk}
                id={id}
                updateSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.KOMMENTAR, validation)}
                maxAntallTegn={kommentar.maxAntallTegn}
              />
            ) : null}
            {vedlegg ? (
              <Vedlegg
                readonly={readonly}
                forhandsvisning={forhandsvisning}
                vedlegg={sporsmalSvar.vedlegg || []}
                obligatorisk={vedlegg.obligatorisk}
                sporsmalId={id}
                updateSporsmalSvar={updateSporsmalSvar}
                validationErrors={filterByType(svarTypes.VEDLEGG, validation)}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

SporsmalSvar.propTypes = {
  sporsmal: sporsmalShape.isRequired,
  updateSporsmalSvar: PropTypes.func.isRequired,
  sporsmalSvar: PropTypes.object.isRequired,
  ingenMerknader: PropTypes.bool.isRequired,
  validation: PropTypes.array.isRequired,
  readonly: PropTypes.bool.isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const sporsmalId = ownProps.sporsmal.id;
  return {
    ingenMerknader: state.skjemaSvar.ingenMerknader,
    sporsmalSvar: getSporsmalSvar(sporsmalId, state.skjemaSvar),
    validation: getValidation(sporsmalId, state.validation.sporsmal),
    locale: state.locale,
  };
};

export default connect(mapStateToProps)(SporsmalSvar);
